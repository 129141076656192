import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { env } from "./env/env";

// Below are lazy-loaded components to enable code splitting.
// This helps Webpack to split our .js file into multiple smaller files,
// (enabling parallel download of multiple files at once)
// and lets React Router load pages only when that page is requested.
// The suspense "fallback" prop contains the React componenT that is shown
// while each page is loading.
// Reference:
// https://reactrouter.com/en/main/route/lazy
const Login = React.lazy(() => import("./components/Login"));
const DataContact = React.lazy(() => import("./components/DataContact"));
const Register = React.lazy(() => import("./components/Register"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const ProtectedRoute = React.lazy(() => import("./components/ProtectedRoute"));
const ForgotPassword = React.lazy(() => import("./components/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const GetLocation = React.lazy(() => import("./components/GetLocation"));
const Stores = React.lazy(() => import("./components/Stores"));
const StorePage = React.lazy(() => import("./components/StorePage"));
const Profile = React.lazy(() => import("./components/Profile"));
const Cart = React.lazy(() => import("./components/Cart"));
const Checkout = React.lazy(() => import("./components/Checkout"));
const Success = React.lazy(() => import("./components/Success"));
const PageNotFound = React.lazy(() => import("./components/PageNotFound"));
const Faq = React.lazy(() => import("./components/Faq"));
const TermsAndConditions = React.lazy(
  () => import("./components/TermsAndConditions"),
);
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const SelectReceipt = React.lazy(() => import("./components/SelectReceipt"));
const WaitingVerification = React.lazy(
  () => import("./components/WaitingVerification"),
);
const VerificationComplete = React.lazy(
  () => import("./components/VerificationCompleted"),
);
const ScanBags = React.lazy(() => import("./components/ScanBags"));
const ScanBagsTest = React.lazy(() => import("./components/ScanBagsTest"));
const ConfirmBags = React.lazy(() => import("./components/ConfirmBags"));

Sentry.init({
  dsn: "https://70967d72c1d4ea205367568c1f4bde6b@o4506546201231360.ingest.sentry.io/4506546202935296",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://backend.wecarrybags.co.uk",
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <Suspense fallback={<>Loading</>}>
      <AuthContextProvider>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/data-contact" element={<DataContact />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset/:email/:code" element={<ResetPassword />} />
          <Route
            path="/waiting-verification/:email/:id"
            element={<WaitingVerification />}
          />
          <Route path="/verify/:email/:id" element={<VerificationComplete />} />
          <Route
            path="/stores"
            element={
              <ProtectedRoute>
                <Stores />
              </ProtectedRoute>
            }
          />
          <Route
            path="/store/:id"
            element={
              <ProtectedRoute>
                <StorePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route path="/getlocation" element={<GetLocation />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <Success />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receipt"
            element={
              <ProtectedRoute>
                <SelectReceipt />
              </ProtectedRoute>
            }
          />
          <Route
            path="/scan-bags"
            element={
              <ProtectedRoute>
                <ScanBags />
              </ProtectedRoute>
            }
          />
          {env.production === false && (
            <Route path="/scan-bags-test" element={<ScanBagsTest />} />
          )}
          <Route
            path="/confirm-bags"
            element={
              <ProtectedRoute>
                <ConfirmBags />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthContextProvider>
    </Suspense>
  );
}

export default App;
